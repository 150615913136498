import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import { ButtonLink, ButtonLinkDesign } from 'components/ButtonLink/ButtonLink'
import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { FC } from 'react'

import { Feature } from './components/Feature/Feature'
import styles from './StepsModule.module.scss'

type FeaturesType = {
  stepNumber: number
  title: string
  description: string
  imgSrc: string
}

type StepsModuleProps = {
  title: string
  subtitle: string
  buttonText: string
  buttonLink?: string
  buttonOnClick?: () => void
  features: FeaturesType[]
  hideSteps?: boolean
  wrapperClassName?: string
}

export const StepsModule: FC<StepsModuleProps> = ({
  title,
  subtitle,
  buttonText,
  buttonLink,
  features,
  hideSteps,
  wrapperClassName,
  buttonOnClick,
}) => {
  return (
    <section className={styles.howWorksSection}>
      <div className={`${styles.howWorksContainer} ${styles.container}`}>
        <Typography typographyType={TypographyType.H2_TITLE} className={styles.title}>
          {title}
        </Typography>

        <Typography typographyType={TypographyType.PARAGRAPH} className={styles.subtitle}>
          {subtitle}
        </Typography>

        <div className={classNames(styles.featuresWrapper, wrapperClassName)}>
          {features.map((feature, featureIndex) => (
            <Feature
              key={feature.title}
              stepNum={feature.stepNumber}
              title={feature.title}
              description={feature.description}
              image={feature.imgSrc}
              hideSteps={hideSteps}
              isFirstChild={featureIndex === 0}
              lastInTwoRows={featureIndex === features.length - 1}
            />
          ))}
        </div>

        <div className={styles.howWorksButtonWrapper}>
          {buttonLink ? (
            <ButtonLink href={buttonLink} design={ButtonLinkDesign.BLUE} className={styles.howWorksButton}>
              {buttonText}
            </ButtonLink>
          ) : (
            <Button className={styles.howWorksButton} onClick={buttonOnClick}>
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </section>
  )
}
