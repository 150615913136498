import classNames from 'classnames'
import { ButtonLink, ButtonLinkDesign } from 'components/ButtonLink/ButtonLink'
import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { FC, useEffect, useRef } from 'react'

import styles from './Hero.module.scss'

type HeroProps = {
  fixedActionButton?: boolean
  title: string
  subtitle: string
  imgSrc: string
  buttonText: string
  buttonLink: string
  className?: string
  titleClass?: string
  subtitleClass?: string
  fixedButtonText?: string
  fixedButtonLink?: string
  externalLink?: boolean
}

export const Hero: FC<HeroProps> = ({
  fixedActionButton = true,
  title,
  subtitle,
  imgSrc,
  buttonText,
  buttonLink,
  className,
  titleClass,
  fixedButtonText,
  fixedButtonLink,
  subtitleClass,
  externalLink,
}) => {
  const mobileButtonRef = useRef<HTMLDivElement>(null)
  const desktopButtonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (fixedActionButton) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: [0, 1],
      }

      // for mobile
      const $buttonContainerMobile = mobileButtonRef.current
      const mobileCallback = (entries: any[]) => {
        if (!$buttonContainerMobile) return
        const button = $buttonContainerMobile.children[0].classList
        const buttonStyle = styles.heroButtonFixed
        entries.forEach((entry) => {
          if (entry && entry.isIntersecting) {
            button.remove(buttonStyle)
          } else {
            button.add(buttonStyle)
          }
        })
      }
      const mobileObserver = new IntersectionObserver(mobileCallback, options)
      mobileObserver.observe($buttonContainerMobile as Element)

      // for desktop
      const $headerButtonDesktop = document.getElementById('find-home-desktop-button')
      const desktopCallback = (entries: any[]) => {
        if (!$headerButtonDesktop) return
        entries.forEach((entry) => {
          if (entry && entry.isIntersecting) {
            $headerButtonDesktop.style.display = 'none'
          } else {
            $headerButtonDesktop.style.display = 'block'
          }
        })
      }
      const desktopObserver = new IntersectionObserver(desktopCallback, options)
      const desktopTarget = desktopButtonRef.current
      desktopObserver.observe(desktopTarget as Element)

      return () => {
        mobileObserver.unobserve($buttonContainerMobile as Element)
        desktopObserver.unobserve(desktopTarget as Element)
      }
    }
    return () => {}
  }, [fixedActionButton])

  return (
    <div className={classNames(styles.heroWrapper, styles.containerFluid, className)}>
      <div className={styles.container}>
        <div className={styles.heroText}>
          <Typography typographyType={TypographyType.H1_TITLE} className={classNames(styles.title, titleClass)}>
            {title}
          </Typography>
          <Typography type="p" typographyType={TypographyType.H3_TITLE} className={classNames(styles.subtitle, subtitleClass)}>
            {subtitle}
          </Typography>
          <div ref={desktopButtonRef}>
            <ButtonLink href={buttonLink} design={ButtonLinkDesign.RED} className={styles.heroButton} external={externalLink}>
              {buttonText}
            </ButtonLink>
          </div>
        </div>
      </div>
      <img className={styles.coverImage} src={imgSrc} alt={title} />
      <div ref={mobileButtonRef} className={classNames(styles.heroButtonMobileContainer, fixedActionButton && styles.buttonIsFixed)}>
        <ButtonLink href={fixedButtonLink || buttonLink} design={ButtonLinkDesign.RED} className={styles.heroButton} external>
          {fixedButtonText || buttonText}
        </ButtonLink>
      </div>
    </div>
  )
}
