import classNames from 'classnames'
import { Icon, IconColor, IconName } from 'components/DataDisplay/Icon/Icon'
import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { VideoPopup } from 'components/VideoPopup/VideoPopup'
import { useTranslation } from 'hooks/useTranslation'
import { FC } from 'react'

import styles from './Testimonial.module.scss'

type VideoType = {
  thumbnailUrl: string
  videoUrl: string
  videoTitle: string
}

type ImageType = {
  imageUrl: string
}

export type FeatureProps = {
  name: string
  quote: string
  avatarRoute?: string
  thumb: VideoType | ImageType
  isSingular?: boolean
  customText?: string
  className?: string
}

export const Testimonial: FC<FeatureProps> = ({ name, quote, avatarRoute, thumb, isSingular = false, customText = '', className }) => {
  const { t } = useTranslation()
  const nameSubtitle = customText || (isSingular ? t('home:testimonials.date-singular') : t('home:testimonials.date'))
  return (
    <div className={classNames(styles.quoteWrapper, className)}>
      <div className={styles.data}>
        <Icon name={IconName.DISCUSS} color={IconColor.RED} size={54} />
        <Typography typographyType={TypographyType.H3_TITLE} className={styles.quote}>
          {quote}
        </Typography>
        <div className={styles.user}>
          {avatarRoute && <img src={avatarRoute} className={styles.avatar} alt={name} />}
          <div>
            <Typography typographyType={TypographyType.SMALL_PARAGRAPH} className={styles.name}>
              {name}
            </Typography>
            <Typography typographyType={TypographyType.SMALL_PARAGRAPH} className={styles.date}>
              {nameSubtitle}
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.thumbnail}>
        {'imageUrl' in thumb && <img src={thumb.imageUrl} alt={name} />}
        {'thumbnailUrl' in thumb && <VideoPopup thumbnailUrl={thumb.thumbnailUrl} videoUrl={thumb.videoUrl} videoTitle={thumb.videoTitle} />}
      </div>
    </div>
  )
}
