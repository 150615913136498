import { Icon, IconName } from 'components/DataDisplay/Icon/Icon'
import { useTranslation } from 'hooks/useTranslation'
import React, { FC, useEffect, useState } from 'react'

import styles from './VideoPopup.module.scss'

type VideoPopupProps = {
  videoUrl: string
  thumbnailUrl: string
  videoTitle: string
}

export const VideoPopup: FC<VideoPopupProps> = ({ videoUrl, thumbnailUrl, videoTitle }) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const classList = document.querySelector('body')?.classList

    return isOpen ? classList?.add(styles.noScroll) : classList?.remove(styles.noScroll)
  }, [isOpen])

  useEffect(() => {
    const _listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', _listener)
    } else {
      document.removeEventListener('keydown', _listener)
    }

    return () => document.removeEventListener('keydown', _listener)
  }, [isOpen])

  return (
    <div>
      <button
        type="button"
        className={styles.container}
        onClick={() => setIsOpen(true)}
        title={t('common:common.video-popup.trigger', { title: videoTitle })}
      >
        <img src={thumbnailUrl} alt="" />
        <div className={styles.popupTrigger}>
          <Icon name={IconName.PLAY_MINI} size={30} />
        </div>
      </button>

      {isOpen && (
        <div
          role="dialog"
          aria-label={t('common:common.video-popup.overlay-title')}
          className={styles.overlay}
          onClick={() => setIsOpen(false)}
          onKeyDown={() => setIsOpen(false)}
        >
          <div className={styles.videoIframe}>
            <iframe
              width="970"
              height="546"
              src={videoUrl}
              title={videoTitle}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </div>
  )
}
