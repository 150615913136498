import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { FC } from 'react'

import styles from './Feature.module.scss'

export type FeatureProps = {
  stepNum: number
  title: string
  description: string
  image: string
  isFirstChild?: boolean
  isLastchild?: boolean
  hideSteps?: boolean
  lastInTwoRows?: boolean
}

export const Feature: FC<FeatureProps> = ({
  stepNum,
  title,
  description,
  image,
  isFirstChild = false,
  isLastchild = false,
  hideSteps = false,
  lastInTwoRows = false,
}) => {
  return (
    <div
      className={`${styles.featureWrapper} ${isFirstChild ? ` ${styles.firstChild}` : ''} ${isLastchild ? ` ${styles.lastChild}` : ''} ${
        hideSteps ? ` ${styles.hideSteps}` : ''
      } ${lastInTwoRows ? styles.lastInTwoRows : ''}`}
    >
      <div className={`${styles.featureContainer} ${styles.inMobile}`}>
        <div className={styles.featureStepper}>
          <div className={styles.border} />
        </div>
        <img src={image} alt={title} className={styles.image} />
      </div>
      <div className={styles.featureContainer}>
        <div className={styles.featureStepper}>
          <Typography typographyType={TypographyType.H3_TITLE} className={styles.step}>
            {stepNum}
          </Typography>
          <div className={styles.border} />
        </div>
        <div className={styles.featureData}>
          <Typography typographyType={TypographyType.H2_TITLE} className={styles.title}>
            {title}
          </Typography>
          <Typography typographyType={TypographyType.PARAGRAPH} className={styles.description}>
            {description}
          </Typography>
        </div>
      </div>
    </div>
  )
}
